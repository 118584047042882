import React from 'react';
import Layout from '../components/global/Layout';
import PageHeader from '../components/global/PageHeader';
import styled from 'styled-components';

const StyledFormContainer = styled.div`
	@media screen and (max-width: 992px) {
		margin-top: 20px;
	}

	input[type=text],
	input[type=email],
	textarea {
		width: 100%;
		margin-bottom: 15px;
		margin-top: 5px;
		padding: 10px;
		border: 2px solid #ededed;
		font-family: Poppins, sans-serif;

		:focus {
			border-color: #ee3364;
			outline: 0;
		}
	}

	label {
		font-size: 18px;
		font-family: PT Serif, sans-serif;
	}

	textarea {
		margin-bottom: 5px;
	}

	button[type=submit] {
		background-color: #ee3364;
		color: white;
		text-transform: uppercase;
		padding: 10px 30px;
		font-size: 14px;
		border-radius: 0;
		border: none;
		letter-spacing: 1px;
		font-family: Poppins, sans-serif;
		cursor: pointer;
		font-weight: bold;

		:hover {
			background-color: #1f0e49;
			transition: 2s ease;
		}

		:focus {
			outline: solid 4px #1f0e49;
		}
	}
`;

const ContactPage = () => {
	return (
		<Layout>
			<PageHeader title="Contact" />
			<main>
				<section className="section-padding">
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<h2 className="section-heading">get in touch</h2>
								<p style={{ maxWidth: 450 }}>
									Want to chat or work together? Don't hesitate to contact me via the contact form.
								</p>
							</div>
							<div className="col-md-6">
								<StyledFormContainer>
									<form
										name="contact"
										method="post"
										data-netlify="true"
										data-netlify-honeypot="bot-field"
									>
										<input type="hidden" name="form-name" value="contact" />
										<label htmlFor="name">Name</label>
										<input id="name" name="name" type="text" required />
										<label htmlFor="email">Email</label>
										<input id="email" name="email" type="email" required />
										<label htmlFor="message">Enter your message here...</label>
										<textarea id="message" name="message" rows="6" /> <br />
										<button type="submit">Submit</button>
									</form>
								</StyledFormContainer>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default ContactPage;
